import router from './router'
import store from './store'
import { createApp } from 'vue'
import App from './App.vue'
import { i18n } from './i18n'
import VCalendar from 'v-calendar'
import VueClientRecaptcha from 'vue-client-recaptcha'

createApp(App)
    .use(i18n)
    .use(VCalendar)
    .use(store)
    .use(router)
    .component('VueClientRecaptcha', VueClientRecaptcha)
    .mount('#app')
