import { createStore } from 'vuex'

export default createStore({
    state: {
        mapClosed: false,
    },
    getters: {},
    mutations: {},
    actions: {},
    modules: {},
})
