
import { Options, Vue } from 'vue-class-component'
import { useStore } from 'vuex'

@Options({})
export default class Header extends Vue {
    store = useStore()

    openMapBigger() {
        this.store.state.mapClosed = true
    }
}
