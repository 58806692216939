
import { Options, Vue } from 'vue-class-component'
import Header from './components/header.vue'
import Footer from './components/footer.vue'
import { i18n, setI18nLanguage } from './i18n'
import Maps from './components/maps.vue'

@Options({
    components: {
        Footer,
        Header,
        Maps,
    },
})
export default class App extends Vue {
    tabClosed = false
    trad = i18n
    translate(languageCode: string) {
        setI18nLanguage(this.trad as any, languageCode)
    }
}
