import { nextTick } from 'vue'
import { createI18n } from 'vue-i18n'
import english from './assets/locales/en.json'
import french from './assets/locales/fr.json'

export const SUPPORT_LOCALES = ['en', 'fr']

export const i18n = createI18n({
    locale: 'fr',
    fallbackLocale: 'en',
    messages: { en: english, fr: french },
})

export function setI18nLanguage(
    i18n: { mode: string; global: { locale: { value: any } } },
    locale: string
) {
    if (i18n.mode === 'legacy') {
        ;(i18n.global as any).locale = locale
    } else {
        i18n.global.locale.value = locale
    }
    /**
     * NOTE:
     * If you need to specify the language setting for headers, such as the `fetch` API, set it here.
     * The following is an example for axios.
     *
     * axios.defaults.headers.common['Accept-Language'] = locale
     */
    document.querySelector('html')!.setAttribute('lang', locale)
}

export async function loadLocaleMessages(
    i18n: { global: { setLocaleMessage: (arg0: any, arg1: any) => void } },
    locale: any
) {
    // load locale messages with dynamic import
    const messages = await import(
        /* webpackChunkName: "locale-[request]" */ `./assets/locales/${locale}.json`
    )
    // set locale and locale message
    i18n.global.setLocaleMessage(locale, messages.default)

    return nextTick()
}
