import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'home',
        component: HomeView,
    },
    {
        path: '/network',
        name: 'network',
        component: () =>
            import(
                /* webpackChunkName: "network" */ '../views/WorldWideNetworkView.vue'
            ),
    },
    {
        path: '/commitments',
        name: 'commitments',
        component: () =>
            import(
                /* webpackChunkName: "commitments" */ '../views/CommitmentView.vue'
            ),
    },
    {
        path: '/quotation',
        name: 'quotation',
        component: () =>
            import(
                /* webpackChunkName: "quotation" */ '../views/QuotationView-2.vue'
            ),
    },
    {
        path: '/contact',
        name: 'contact',
        component: () =>
            import(
                /* webpackChunkName: "contact" */ '../views/ContactView.vue'
            ),
    },
    {
        path: '/expertise',
        name: 'expertise',
        component: () =>
            import(
                /* webpackChunkName: "expertiseArea" */ '../views/ExpertiseAreaView.vue'
            ),
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
})

export default router
