
import { Options, Vue } from 'vue-class-component'
import { i18n, setI18nLanguage } from '../i18n'

@Options({})
export default class Header extends Vue {
    trad = i18n
    translate(languageCode: string) {
        setI18nLanguage(this.trad as any, languageCode)
    }
}
